<template>
  <div class="home">
    <div class="Topnavbar">
      <TopNavBarIla26 @etatMenuIla26="handelEventetatMenuIla26" />
    </div>
    <div class="body-container">
      <menu-ila-26 :mini="comutedEtatMenuIla26" />
      <div class="body">
        <TopNavBar />
        <div
          v-if="computedRouterLinkFacture"
          class="d-flex align-center justify-space-between"
        >
          <div class="ref-curent-url">Factures / {{ $route?.meta?.title }}</div>
          <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
            Préparation du fichier en cours ...
          </div>
        </div>
        <div class="body-content"><router-view /></div>
      </div>
    </div>

    <FlashMessage
      :position="'right bottom'"
      class="flach-Message-index"
    ></FlashMessage>
    <portal to="new-salary-modal">
      <div
        v-if="getProgressValidationP != null && getProgressValidationP < 100"
        class="slider-style"
      >
        <div class="data-slider-style">
          <div
            class="span mr-4"
            v-if="getProgressValidation && this.getProgressValidation.year"
          >
            <strong>Année : </strong>{{ this.getProgressValidation.year }}
          </div>
          <div
            class="span"
            v-if="getProgressValidation && this.getProgressValidation.semaine"
          >
            <strong> Semaine : </strong>
            {{ this.getProgressValidation.semaine }}
          </div>
        </div>
        <div>
          <v-progress-linear
            :model-value="getProgressValidationP"
            color="light-blue"
            height="10"
            striped
          ></v-progress-linear>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MenuIla26 from './navBar/menuIla26.vue';
import TopNavBar from './navBar/TopNavBar.vue';
import TopNavBarIla26 from './navBar/topNavBarIla26.vue';
export default {
  name: 'Home',
  components: {
    TopNavBar,
    MenuIla26,
    TopNavBarIla26
  },

  data() {
    return {
      etatMenuIla26: false
    };
  },
  computed: {
    ...mapGetters([
      'getnewNotification',
      'getNoutificationToShow',
      'getUserData',
      'getProgressValidation',
      'getProgressValidationP',
      'computedRouterLinkFacture',
      'getShowNotifExportFile'
    ]),
    comutedEtatMenuIla26() {
      return this.etatMenuIla26;
    }
  },
  mounted() {
    if (this.getUserData !== null) {
      this.getToken();
    }
  },
  methods: {
    ...mapActions(['getToken']),
    handelEventetatMenuIla26(event) {
      this.etatMenuIla26 = event;
    }
  },
  watch: {
    getnewNotification: function() {
      const objectFlash = {
        blockClass: 'custom-block-class',
        contentClass: 'custom-content-class'
      };
      // switch (
      //   this.getNoutificationToShow.notification_type
      // case 'bill_refuse':
      //   objectFlash.status = 'error';
      //   objectFlash.title = 'SALAIRE REFUSÉ';
      //   objectFlash.message =
      //     this.getNoutificationToShow.from +
      //     ' a refusé salaire de  ' +
      //     this.getNoutificationToShow.victime.name;

      //   break;
      // case 'bill_demande':
      //   objectFlash.status = 'info';
      //   objectFlash.title = 'SALAIRE VALIDÉ';
      //   objectFlash.message =
      //     ' salaire de regie validé pour semaine  ' +
      //     this.getNoutificationToShow.semaine +
      //     'dans lannée ' +
      //     this.getNoutificationToShow.year;

      //   break;
      // case 'bill_payed_successfully':
      //   objectFlash.status = 'success';
      //   objectFlash.title = 'FACTURE PAYÉ';
      //   objectFlash.message =
      //     'Facture de' + this.getNoutificationToShow.victime.name + ' payé';

      //   break;
      // case 'regie_bill_waiting_for_validation':
      //   objectFlash.status = 'info';
      //   objectFlash.title = 'FACTURE UPLOADÉ';
      //   objectFlash.message =
      //     this.getNoutificationToShow.from +
      //     ' a uploadé facture de  ' +
      //     this.getNoutificationToShow.victime.name;

      //   break;
      // case 'employee_bill_waiting_for_validation':
      //   objectFlash.status = 'info';
      //   objectFlash.title = 'FACTURE UPLOADÉ';
      //   objectFlash.message =
      //     this.getNoutificationToShow.from +
      //     ' a uploadé facture de  ' +
      //     this.getNoutificationToShow.victime.name;

      //   break;
      // case 'new_bills':
      //   objectFlash.status = 'success';
      //   objectFlash.title = 'SEMAINE VAlIDÉ';
      //   objectFlash.message =
      //     'Semaine ' +
      //     this.getNoutificationToShow.semaine +
      //     "dans l'année" +
      //     this.getNoutificationToShow.year +
      //     'validé';

      //   break;
      // case 'revalidate_salary':
      //   objectFlash.status = 'error';
      //   objectFlash.title = '';
      //   objectFlash.message =
      //     "S'il vous plait revalide la salaire de " +
      //     this.getNoutificationToShow.victime +
      //     'pour la semaine' +
      //     this.getNoutificationToShow.semaine +
      //     " dans l'année " +
      //     this.getNoutificationToShow.year;

      //   break;
      // case 'revalidate_salary_regie':
      //   objectFlash.status = 'error';
      //   objectFlash.title = '';
      //   objectFlash.message =
      //     "S'il vous plait reuploade la  facture " +
      //     'pour la semaine' +
      //     this.getNoutificationToShow.semaine +
      //     " dans l'année " +
      //     this.getNoutificationToShow.year;

      //   break;
      // case 'employee_bill_updated':
      //   objectFlash.status = 'info';
      //   objectFlash.title = 'Prime Modifié';
      //   objectFlash.message =
      //     ' prime de ' +
      //     this.getNoutificationToShow.victime +
      //     ' modifié  pour semaine  ' +
      //     this.getNoutificationToShow.semaine +
      //     'dans lannée ' +
      //     this.getNoutificationToShow.year;

      //   break;
      // case 'bill_redemande':
      //   objectFlash.status = 'error';
      //   objectFlash.title = 'FACTURE REFUSÉ';
      //   objectFlash.message =
      //     this.getNoutificationToShow.from +
      //     ' a refusé la facture de  ' +
      //     this.getNoutificationToShow.victime;

      //   break;
      // case 'new_salary':
      //   objectFlash.status = 'info';
      //   objectFlash.title = 'SALAIRES GÉNERE';
      //   objectFlash.message =
      //     this.getNoutificationToShow.total_salarys_count +
      //     ' salaires génere Pour Semaine ' +
      //     this.getNoutificationToShow.semaine +
      //     'dans lannée ' +
      //     this.getNoutificationToShow.year;

      //   break;
      // ) {
      // }
      objectFlash.status =
        this.getNoutificationToShow.type == 'SUCCESS' ? 'success' : 'error';
      // objectFlash.title = 'FICHIER A TELECHERGER';
      objectFlash.message = this.getNoutificationToShow.comment;
      this.flashMessage.show({
        status: objectFlash.status,
        title: objectFlash.title,
        blockClass: objectFlash.blockClass,
        contentClass: objectFlash.contentClass,
        message: objectFlash.message
      });
    },
    $route() {
      this.getToken();
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .ref-curent-url {
    font-size: 9px;
    color: #999999;
    text-transform: uppercase;
    padding: 8px;
  }
  img {
    width: 300px;
    max-width: 100%;
  }
  .body-container {
    display: flex;
    height: calc(100% - 48px);
    width: 100%;
    margin-top: 48px;
  }
  .body {
    display: block;
    position: relative;
    width: 100%;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    // margin-top: 48px;
    // height: calc(100% - 48px);
    overflow: hidden;
    .body-content {
      height: 100%;
      // margin-left: 266px;
      // z-index: 7;
    }
  }
  .Topnavbar {
    z-index: 1;
  }
}
.slider-style {
  position: fixed;
  width: 300px;
  bottom: 18px;
  right: 11px;
  z-index: 101;
  background-color: #bac4c9;
  box-shadow: 3px 5px 8px rgb(0 0 0 / 83%);
  padding: 18px;
  font-size: 13px;
  display: block;
  border-radius: 5px;
  .data-slider-style {
    padding-bottom: 10px;
    display: flex;
  }
}
</style>
<style lang="scss">
.custom-block-class {
  width: 220px !important;
  z-index: 5;
  p {
    margin-bottom: 0px;
  }
}
.custom-content-class {
  font-size: 10px !important;
}
.flach-Message-index {
  z-index: 10000000 !important;
}
</style>
