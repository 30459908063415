<template>
  <nav class="nav-cdg-fact">
    <div class="profile-corner mr-1">
      <v-tooltip bottom color="#311B92">
        <template v-slot:activator="{ on, attrs }">
          <div
            size="sm"
            v-on="on"
            v-bind="attrs"
            class="block-icon-export-list "
            @click="$router.push('/setting')"
          >
            <font-awesome-icon :icon="['fal', 'gear']" />
          </div>
        </template>
        <span>Paramètres</span>
      </v-tooltip>
    </div>
    <div class="profile-corner mr-1">
      <InsertionBDD :phase="2" title="Insertion BDD" />
    </div>
    <div class="profile-corner"><exportListCdg /></div>
    <div class="menu-corner">
      <!-- <div class="logo pl-3 pr-3">
        <router-link :to="'/'" class="router-link">
          <div class="logo-cdg">CDG</div>
        </router-link>
      </div> -->
      <div class="menuTop"><Menu /></div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Topbar',

  data() {
    return {
      currentType: null,
      TypeProjectList: [
        { name: 'Factures libres', value: 'libres' },
        { name: 'Factures SCI', value: 'sci' }
      ],
      date_fin: null,
      date_debut: null,
      societe: []
    };
  },
  components: {
    // DropDownNotification: () => import('./DropdownNotification.vue'),
    // DropDownSetting: () => import('./DropdownSetting.vue'),
    exportListCdg: () => import('@/views/component/exportListCdg'),
    InsertionBDD: () => import('@/views/InsertionBDD.vue'),
    Menu: () => import('./menu.vue')
  },
  mounted() {
    this.currentType = this.getCurrentType;
  },

  methods: {
    ...mapActions([
      'ChangeCurrentTpe',
      'exportFactureCdg',
      'getSettingFilialeTh'
    ]),
    handleChangeCurrentType(e) {
      this.ChangeCurrentTpe(e);
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    resetModal() {
      this.date_debut = null;
      this.date_fin = null;
      this.societe = [];
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleExportFactureCdg() {
      this.exportFactureCdg({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        societe: this.societe.length
          ? this.societe.map(item => {
              return item.id;
            })
          : this.getSettingFilialesTh.map(item => {
              return item.id;
            })
      });
      this.hideModal('extract-modal-cdg');
    },
    getAllSociete() {
      this.getSettingFilialeTh();
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'getCurrentType',
      'getErrorExportAllFactureCdg',
      'getLoadingExportAllFactureCdg',
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'isSuperAdminGetter',
      'checkPermission'
    ]),
    computeSuperAdmin: function() {
      return this.getUserData && this.getUserData.role === 'admin';
    },
    permission: function() {
      return this.getUserData && this.getUserData.permissions;
    },
    ComputedTypeProjectList() {
      let list = this.TypeProjectList;
      if (!this.checkPermission('FSTH')) {
        list = list.filter(item => item.value != 'sci');
      }
      if (!this.checkPermission('FL')) {
        list = list.filter(item => item.value != 'libres');
      }
      return list;
    }
  },
  watch: {
    getCurrentType(value) {
      this.currentType = this.getCurrentType;
    }
  }
};
</script>

<style lang="scss" scoped>
.router-link {
  text-decoration: none;
}
.logo-cdg {
  color: #704ad1;
  padding-left: 10px;
  font-size: 24px;
  font-family: unset;
  letter-spacing: 0.5px;
  text-decoration: none;
  font-weight: 700;
}

.nav-cdg-fact {
  // position: absolute;
  top: 0;
  right: 0;
  left: 0px;
  height: 42px;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 1px 13px 0px #00000012;

  .menu-corner {
    display: flex;
    height: 42px;
    .icon {
      display: none;
    }
    .logo {
      display: flex;
      font-size: 13px;
      font-weight: 800;
      align-items: center;
      padding: 0 30px;
      padding-right: 8%;
      img {
        width: 71px;
      }
    }
  }

  .profile-corner {
    float: right;
    margin-top: 2px;
  }
}
@media screen and (max-width: 1200px) {
  .nav-cdg-fact {
    .menu-corner {
      .icon {
        background-color: transparent;
        float: left;
        display: block;
        border: none;
        height: 37px;
        width: 37px;
        color: #4d4bac;
        border-radius: 5px;
        outline: 0;
        margin-top: 6px;
        &:hover {
          outline: 0;
          border-radius: 50%;
          background-color: rgba(219, 217, 217, 0.54);
        }
        .bar-icon {
          outline: 0;
        }
      }
      .menuTop {
        display: none;
        width: 0px;
      }
    }
  }
}
.date-picker-export-globale {
  width: 100%;
}
</style>
