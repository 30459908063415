<template>
  <nav>
    <div class="profile-corner mt-0">
      <DropDownSetting />
    </div>
    <div class="profile-corner">
      <div class="vertical-line-navbar"></div>
    </div>
    <div class="profile-corner mr-4">
      <DropDownNotification />
    </div>
    <!-- <div class="profile-corner">
      <div class="vertical-line-navbar"></div>
    </div> -->

    <div class="menu-corner">
      <div class="logo pl-3 pr-3">
        <img src="@/assets/ilan26.png" />
        <!-- <router-link :to="'/'" class="router-link">
          <div class="logo-cdg">CDG</div>
        </router-link> -->
      </div>
      <div
        class="icon-enevt-menu-ile26 d-flex"
        @click="handelEventEtatMenu"
        :class="{ 'ml-2': etatMenuIla26 }"
      >
        <div size="sm" class="btn-sidebar">
          <font-awesome-icon
            :icon="
              etatMenuIla26
                ? ['fal', 'arrow-right-to-line']
                : ['fal', 'arrow-left-to-line']
            "
            class="icon-lock-menuila26"
          />
        </div>
        <div size="m" class="btn-sidebar-icon">
          <font-awesome-icon
            :icon="['fas', 'grid-2']"
            class="icon-lock-menuila26 "
          />
        </div>
      </div>
      <!-- <div class="vertical-line-navbar mt-3"></div> -->
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Topbar',

  data() {
    return {
      currentType: null,
      TypeProjectList: [
        { name: 'Factures libres', value: 'libres' },
        { name: 'Factures SCI', value: 'sci' }
      ],
      date_fin: null,
      date_debut: null,
      societe: [],
      etatMenuIla26: false
    };
  },
  components: {
    DropDownNotification: () => import('./DropdownNotification.vue'),
    DropDownSetting: () => import('./DropdownSetting.vue')
    // exportListCdg: () => import('@/views/component/exportListCdg'),
    // InsertionBDD: () => import('@/views/InsertionBDD.vue'),
    // Menu: () => import('./menu.vue')
  },
  mounted() {
    this.currentType = this.getCurrentType;
  },

  methods: {
    ...mapActions(['ChangeCurrentTpe', 'getSettingFilialeTh']),
    handelEventEtatMenu() {
      this.etatMenuIla26 = !this.etatMenuIla26;
      this.$emit('etatMenuIla26', this.etatMenuIla26);
    },
    handleChangeCurrentType(e) {
      this.ChangeCurrentTpe(e);
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    resetModal() {
      this.date_debut = null;
      this.date_fin = null;
      this.societe = [];
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleExportFactureCdg() {
      this.exportFactureCdg({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        societe: this.societe.length
          ? this.societe.map(item => {
              return item.id;
            })
          : this.getSettingFilialesTh.map(item => {
              return item.id;
            })
      });
      this.hideModal('extract-modal-cdg');
    },
    getAllSociete() {
      this.getSettingFilialeTh();
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'getCurrentType',
      'getErrorExportAllFactureCdg',
      'getLoadingExportAllFactureCdg',
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'isSuperAdminGetter',
      'checkPermission'
    ]),
    computeSuperAdmin: function() {
      return this.getUserData && this.getUserData.role === 'admin';
    },
    permission: function() {
      return this.getUserData && this.getUserData.permissions;
    },
    ComputedTypeProjectList() {
      let list = this.TypeProjectList;
      if (!this.checkPermission('FSTH')) {
        list = list.filter(item => item.value != 'sci');
      }
      if (!this.checkPermission('FL')) {
        list = list.filter(item => item.value != 'libres');
      }
      return list;
    }
  },
  watch: {
    getCurrentType(value) {
      this.currentType = this.getCurrentType;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-sidebar-icon,
.btn-sidebar {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  margin-top: 6px;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 1px;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  height: 36px;
  width: 36px;
}
.btn-sidebar,
.btn-sidebar-icon {
  &:hover {
    background: rgb(92, 45, 211);
    .icon-lock-menuila26 {
      color: white !important;
    }
  }
}
.icon-enevt-menu-ile26 {
  color: #3c454b;
  margin-left: 160px;
  // padding-top: 10px;
}
.router-link {
  text-decoration: none;
}
.icon-lock-menuila26 {
  height: 19px;
}
.logo-cdg {
  color: #251e36;
  padding-left: 10px;
  font-size: 24px;
  font-family: unset;
  letter-spacing: 0.5px;
  text-decoration: none;
  font-weight: 700;
}

nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0px;
  height: 48px;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 1px 13px 0px #00000012;

  .menu-corner {
    display: flex;
    height: 48px;
    .icon {
      display: none;
    }
    .logo {
      display: flex;
      font-size: 13px;
      font-weight: 800;
      align-items: center;
      padding: 0 30px;
      padding-right: 8%;
      img {
        width: 71px;
      }
    }
  }

  .profile-corner {
    float: right;
    margin-top: 4px;
  }
}
@media screen and (max-width: 1200px) {
  nav {
    .menu-corner {
      .icon {
        background-color: transparent;
        float: left;
        display: block;
        border: none;
        height: 37px;
        width: 37px;
        color: #4d4bac;
        border-radius: 5px;
        outline: 0;
        margin-top: 6px;
        &:hover {
          outline: 0;
          border-radius: 50%;
          background-color: rgba(219, 217, 217, 0.54);
        }
        .bar-icon {
          outline: 0;
        }
      }
      .menuTop {
        display: none;
        width: 0px;
      }
    }
  }
}
.date-picker-export-globale {
  width: 100%;
}
</style>
