<template>
  <div class="menu-ila26" :class="{ mini: mini }">
    <v-navigation-drawer class="nav-drawer" permanent>
      <!-- HEADER MENU  -->
      <v-list-item :class="{ 'pl-6': !mini, 'pl-4': mini }" class="">
        <div></div>
        <v-tooltip right v-if="mini" content-class="tootltip-home" z-index="10">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="block-icon">
              <v-list-item-avatar v-if="mini" class="avatar-icon">
                <font-awesome-icon
                  class="badge-check-class"
                  :icon="['fas', 'badge-check']"
                />
              </v-list-item-avatar>
            </div>
          </template>
          <span>Generale France Energie</span>
        </v-tooltip>

        <v-list-item-content class="title-menu" v-else>
          <v-list-item-subtitle class="mb-0">
            <span class="title-1"> ENTREPRISE</span>
          </v-list-item-subtitle>
          <v-list-item-title>
            <span class="title-2">GENERALE FRANCE ENERGIE</span>
            <span>
              <font-awesome-icon
                class="badge-check-class"
                :icon="['fas', 'badge-check']"
              />
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class=" pb-2"></v-divider>
      <!-- END HEADER MENU  -->

      <!-- BODY MENU  -->
      <div class="body-menu">
        <template v-for="(menu, i) in getMenuIla26">
          <v-list
            dense
            class="menu-modul "
            nav
            :class="{ mini: mini }"
            :key="'list' + i + menu.title"
          >
            <h4 class="title-2 mt-2 pl-6" v-if="!mini">
              {{ menu.title }}
            </h4>
            <v-list-item
              v-for="(item, index) in menu.sous_menu"
              :key="item.title"
              class="item-menu ml-4 mt-1"
              :class="{
                mini: mini,
                active:
                  selectedItem &&
                  selectedItem.indexC == index &&
                  selectedItem.indexP == i
              }"
              color="#5C2DD3"
              link
              @click="activeMenu(index, i)"
            >
              <a :href="item.url" class="d-flex link">
                <v-tooltip
                  right
                  v-if="mini"
                  content-class="tootltip-home"
                  z-index="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <font-awesome-icon :icon="item.icon" />
                      </v-list-item-icon>
                    </div>
                  </template>
                  <span class="bold-500">{{ item.title }}</span>
                </v-tooltip>
                <template v-else>
                  <v-list-item-icon>
                    <font-awesome-icon :icon="item.icon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ item.title }}
                  </v-list-item-content>
                </template>
              </a>
            </v-list-item>
          </v-list>
          <v-divider
            class="mt-2"
            :key="i + menu.title"
            v-if="i < getMenuIla26.length - 1"
          ></v-divider>
        </template>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: { mini: { default: false } },
  data() {
    return { selectedItem: null };
  },
  computed: {
    ...mapGetters(['getMenuIla26', 'getMenuIla26Error', 'getMenuIla26Loader'])
  },
  methods: {
    ...mapActions(['fetchMenuIla26Liste']),
    activeMenu(indexC, indexP) {
      this.selectedItem = { indexC: indexC, indexP: indexP };
    }
  },
  async mounted() {
    // console.log('test', curent_route);
    // const response = await  this.fetchMenuIla26Liste();
    // if(response){
    const currentHref = window.location.href;
    const currentOrigin = window.location.origin;
    this.getMenuIla26.forEach((element, index) => {
      const indexByHref = element.sous_menu.findIndex(
        child => child.url === currentHref
      );

      if (indexByHref !== -1) {
        this.activeMenu(indexByHref, index);
        return; // Exit the loop if found by href
      }

      const indexByOrigin = element.sous_menu.findIndex(
        child => child.url === currentOrigin
      );

      if (indexByOrigin !== -1) {
        this.activeMenu(indexByOrigin, index);
        return; // Exit the loop if found by origin
      }
    });
    // }
  }
};
</script>
<style scoped lang="scss">
$color: #2f2f2f;
$hover_color: #704ad1;
$bg-item: rgba(92, 45, 211, 0.1);
.menu-ila26 {
  width: 256px;
  transition: width 0.2s;
  &.mini {
    width: 66px;
    transition: width 0.2s;
  }
  .body-menu {
    overflow-y: auto;
    height: calc(100% - 96px);
  }

  .nav-drawer {
    .menu-modul {
      padding-right: 20px !important;
      &.mini {
        padding-right: 16px !important;
      }
    }

    .title-menu {
      padding: 0.4rem 0px 1rem 0px;
      transition: all 0.5s ease 0s;
      flex: 0 0 auto;
      .title-1 {
        color: $hover_color;
        font-weight: 600;
      }
    }
    .title-2 {
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      color: $color;
    }
  }

  .title-item-menu {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }
  .item-menu {
    margin-bottom: 0px !important;
    min-height: 40px !important;
    display: flex;
    white-space: nowrap;
    // &:active {
    //   background-color: $bg-item !important;
    // }
    .link {
      overflow: hidden;
      font-weight: 500;
      font-size: 13px;
      white-space: nowrap;
      text-decoration: none;
      color: $color;
      font-family: 'Montserrat', sans-serif;
      line-height: 15px;
      // &.router-link-exact-active {
      //   &.router-link-active {
      //     color: $hover_color;
      //     font-weight: 600;
      //     font-size: 13px;
      //   }
      // }
    }

    .v-list-item__icon {
      margin-right: 6px !important;
      height: 18px !important;
      align-items: center;
      svg {
        height: 18px !important;
        width: 18px !important;
      }
    }
    &:hover {
      background-color: $bg-item !important;
      .v-list-item__icon,
      .color-icon-current,
      .link {
        color: $hover_color !important;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
  .active {
    background-color: $bg-item !important;
    .v-list-item__icon,
    .color-icon-current,
    .link {
      color: $hover_color !important;
      font-weight: 600 !important;
      font-size: 13px !important;
    }
  }
  .badge-check-class {
    color: $hover_color !important;
    height: 17px;
    vertical-align: sub;
  }
  .avatar-icon {
    .badge-check-class {
      height: 25px !important;
    }
  }
  .v-list-item__content {
    padding: 0px;
    align-self: center;
  }
}

.body-menu::-webkit-scrollbar {
  width: 2px;
  height: 7px;
}
.body-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eae7f6;
  border-radius: 10px;
}
.body-menu::-webkit-scrollbar-thumb {
  background: #cacae3;
  border-radius: 7px;
}
</style>
